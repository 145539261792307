*::-webkit-scrollbar {
    display: none;
}

.active {
    @apply border-input bg-background hover:bg-accent hover:text-accent-foreground border;
}

.list-hyphen::before {
    content: '-';
    margin-right: 0.5rem;
}

.login-wrapper {
    background: linear-gradient(90.08deg, #ffffff 18.73%, rgba(255, 255, 255, 0) 99.93%), url('../assets/main-background.jpg');
    grid-template-rows: repeat(3, 1fr);
}

@media (max-width: 567.9px) {
    .login-wrapper {
        background: linear-gradient(90.08deg, #ffffff 100%, rgba(255, 255, 255, 0) 99.93%), url('../assets/main-background.jpg');
    }
}
